import React, { MouseEventHandler, useEffect, useState } from "react";
import Modal from "components/ui-kit/Modal";
import { getTos } from "../../apis/smartAlertAPI";
import styled from "@emotion/styled";
import { Button, CircularProgress } from "@mui/material";

const StyledModal = styled(Modal)`
  && {
    margin: 0 auto;
  }
`;

interface Props {
  open: boolean;
  handleClose: any;
  onAcknowledge: MouseEventHandler;
}

export default function TosModal({ open, handleClose, onAcknowledge }: Props) {
  const [tosContent, setTosContent] = useState("");
  const [loadingContent, setLoadingContent] = useState(false);
  const [acknowledgeDisabled, setAcknowledgeDisabled] = useState(false);

  const handleScroll = (e: any) => {
    const disabled =
      e.target.scrollHeight - Math.round(e.target.scrollTop) <=
      e.target.clientHeight + 4;

    setAcknowledgeDisabled(!disabled);
  };

  const fetchTosContent = async () => {
    setLoadingContent(true);
    try {
      const data = await getTos();
      setTosContent(data.data);
      setLoadingContent(false);
    } catch (e) {
    } finally {
    }
  };

  useEffect(() => {
    fetchTosContent().then();
  }, []);

  return (
    <StyledModal
      onScroll={handleScroll}
      title={"Terms of service"}
      open={open}
      onClose={handleClose}
    >
      <div style={{ padding: `1rem 2rem 1rem 2rem` }}>
        {loadingContent ? (
          <CircularProgress style={{ margin: `0 auto` }} />
        ) : (
          <>
            <div dangerouslySetInnerHTML={{ __html: tosContent }}></div>
            <Button
              disabled={acknowledgeDisabled}
              variant={"outlined"}
              size={"large"}
              fullWidth
              onClick={onAcknowledge}
            >
              Acknowledge
            </Button>
          </>
        )}
      </div>
    </StyledModal>
  );
}
