import React, { MouseEventHandler, ReactNode, UIEventHandler } from "react";
import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconBase from "components/IconBase";
import { Box, IconButton } from "@mui/material";
import { Dialog as MuiDialog } from "@mui/material";
import Grid from "@mui/material/Grid";

const TitleActions = styled.div``;

const ModalDialog = styled(MuiDialog)`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
`;

const StyledDialogTitle = styled.div`
  && {
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.palette.nonPalette.OUTLINE};
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  && .MuiTypography-root {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;

const StyledTypography = styled(Typography)`
  && {
    /* Headline 4 */
    max-width: 500px;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme }) => theme.palette.primary[700]};
  }
`;

const CloseIconWrapper = styled(Grid)`
  border-left: 2px solid ${({ theme }) => theme.palette.nonPalette.OUTLINE}
  padding-left: 1rem;
  margin-left: 1rem;
`;

interface Props {
  color?: string;
  children?: ReactNode;
  avatarLabel?: string;
  avatarImg?: ImageBitmap;
  selected?: boolean;
  className?: string;
  title?: string;
  body?: ReactNode;
  maxWidth?: number;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  onClose?: MouseEventHandler;
  titleActions?: ReactNode;
  open: boolean;
  onScroll: UIEventHandler;
}

const Modal = ({
  color,
  children,
  avatarLabel,
  avatarImg,
  selected,
  className,
  title,
  body,
  maxWidth,
  primaryButtonLabel,
  secondaryButtonLabel,
  onClose,
  open,
  titleActions,
  onScroll,
  ...rest
}: Props) => {
  return (
    <ModalDialog
      scroll={"paper"}
      open={open}
      PaperProps={{
        onScroll: onScroll,
        style: { borderRadius: "20px" },
      }}
      onClose={onClose}
      fullWidth
      maxWidth={"md"}
      {...rest}
    >
      {title && (
        <>
          <StyledDialogTitle>
            <StyledTypography variant="h4" fontWeight={"300"}>
              {title}
            </StyledTypography>

            <Box justifyContent="flex-end">
              {titleActions ? (
                <Grid>
                  <TitleActions>{titleActions}</TitleActions>
                </Grid>
              ) : null}
              {onClose ? (
                <CloseIconWrapper>
                  <IconButton onClick={onClose} size="large">
                    <IconBase
                      style={{ fontSize: 36 }}
                      wght={600}
                      iconName="close"
                    />
                  </IconButton>
                </CloseIconWrapper>
              ) : null}
            </Box>
          </StyledDialogTitle>
          <Divider />
        </>
      )}
      {children}
    </ModalDialog>
  );
};

export default Modal;
