import {Box, Button, CircularProgress, Stack, Typography, useTheme} from "@mui/material";
import React, {useRef, useState} from "react";
import {sendAlert} from "apis/smartAlertAPI";
import IconBase from "components/IconBase";
import Spacer from "components/layout/Spacer";
import styled from "@emotion/styled";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {Form, Formik, Field} from "formik";
import MaskedTextField from "../../components/ui-kit/inputs/MaskedTextField";
import Alert from "../../components/Alert";
import * as yup from "yup";

const TriggerContainer = styled.div({});

const InnerContainer = styled.div({
    boxSizing: "border-box",
    maxWidth: "600px",
    padding: "0 5px",
    margin: "0 auto",
});

const triggerValidations = yup.object().shape(
    {
        aliasID: yup
            .string()
            .required("Please select an option."),
        depth: yup
            .string()
            .required("Depth must be a provided"),
        depthDet1: yup
            .string()
            .required("Trigger depth #1 must be provided")
            .matches(/^\d+$/, "Trigger depth #1 must be a number"),
        depthDet2: yup
            .string()
            .required("Trigger depth #2 must be provided")
            .matches(/^\d+$/, "Trigger depth #1 must be a number"),
        depthDet3: yup
            .string()
            .required("Trigger depth #3 must be provided")
            .matches(/^\d+$/, "Trigger depth #3 must be a number"),
    }
);

const Trigger = () => {
    const theme = useTheme();
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [errorSubmitting, setErrorSubmitting] = useState(false);
    const [successSubmitting, setSuccessSubmitting] = useState(false);
    const [loadingSubmission, setLoadingSubmission] = useState(false);
    const formikRef = useRef(null) as any;

    const handleSubmit = async (values: {
        aliasID: string;
        depth: string;
        depthDet1: string;
        depthDet2: string;
        depthDet3: string;

    }) => {
        try {
            await sendAlert(
                values.aliasID,
                values.depth,
                values.depthDet1,
                values.depthDet2,
                values.depthDet3);
            setSuccessSubmitting(true);
            formikRef.current?.resetForm({aliasID: "", depth: 10, depthDet1: 8, depthDet2: 12, depthDet3: 16});
        } catch (e) {
            setErrorSubmitting(true);
        } finally {
            setLoadingSubmission(false);
        }
    };

    return (
        <TriggerContainer>
            <Helmet>
                <title>MyCityAlerts - Demo</title>
            </Helmet>
            <InnerContainer>
                <Box style={{display: "flex", justifyContent: "center"}}>
                    <Stack mt={5} mb={1} direction={"row"} alignItems={"center"}>
                        <Link to={"/"}>
                            <IconBase
                                iconName={"account_balance"}
                                grad={700}
                                fill={1}
                                size={22}
                                style={{
                                    marginRight: 5,
                                }}
                            />
                        </Link>
                        <Link to={"/"} style={{textDecoration: "none"}}>
                            <Typography
                                align={"center"}
                                variant={"h4"}
                                style={{
                                    textTransform: "uppercase",
                                    color: theme.palette.primary[500],
                                    fontWeight: "700",
                                    textDecoration: "none",
                                }}
                            >
                                City<span style={{fontWeight: "300"}}>logo</span>
                            </Typography>
                        </Link>
                    </Stack>
                </Box>

                <Spacer size={10}/>
                <Box>
                    <Typography variant={"h2"}>Sensor Demo</Typography>
                </Box>
                <Spacer size={3}/>
                <Formik
                    innerRef={formikRef}
                    validationSchema={triggerValidations}
                    initialValues={{aliasID: "", depth: "10", depthDet1: "8", depthDet2: "12", depthDet3: "16"}}
                    onSubmit={handleSubmit}
                    validateOnChange={validateOnChange}
                >
                    {({values, handleChange, errors, setFieldValue}) => {
                        return (
                            <Form noValidate>
                                <div id="my-radio-group"><Typography variant={"h4"}>Sensor:</Typography></div>
                                <div role="group" aria-labelledby="my-radio-group">
                                    <div>
                                        <label>
                                            <Field type="radio" name="aliasID"
                                                   value="Stafford00024-Aquia Landing"/>
                                            #024 - Stafford County - Aquia Landing
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <Field type="radio" name="aliasID"
                                                   value="Stafford00314-Harrell Road"/>
                                            #314 - Stafford County - Harrell Road
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <Field type="radio" name="aliasID"
                                                   value="Stafford00315-Brooke Rd./Accokeek Creek2"/>
                                            #315 - Stafford County - Brooke Road (Accokeek Creek)
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <Field type="radio" name="aliasID"
                                                   value="Stafford00380-Brooke Rd./Potomac Creek2"/>
                                            #380 - Stafford County - Brooke Road (Potomac Creek)
                                        </label>
                                    </div>
                                    {Boolean(errors.aliasID) && (
                                        <Typography color={"red"}>{errors.aliasID}</Typography>
                                    )}
                                </div>
                                <Spacer size={3}/>
                                <MaskedTextField
                                    initialValue={values.depth}
                                    type={"NUMBER"}
                                    name={"depth"}
                                    onChange={handleChange}
                                    value={values.depth}
                                    fullWidth
                                    error={Boolean(errors.depth)}
                                    helperText={errors.depth}
                                    label={"Simulated Depth"}
                                />
                                <Spacer size={5}/>
                                <MaskedTextField
                                    initialValue={values.depthDet1}
                                    type={"NUMBER"}
                                    name={"depthDet1"}
                                    onChange={handleChange}
                                    value={values.depthDet1}
                                    fullWidth
                                    error={Boolean(errors.depthDet1)}
                                    helperText={errors.depthDet1}
                                    label={"Depth Trigger #1"}
                                />
                                <Spacer size={5}/>
                                <MaskedTextField
                                    initialValue={values.depthDet2}
                                    type={"NUMBER"}
                                    name={"depthDet2"}
                                    onChange={handleChange}
                                    value={values.depthDet2}
                                    fullWidth
                                    error={Boolean(errors.depthDet2)}
                                    helperText={errors.depthDet2}
                                    label={"Depth Trigger #1"}
                                />
                                <Spacer size={5}/>
                                <MaskedTextField
                                    initialValue={values.depthDet3}
                                    type={"NUMBER"}
                                    name={"depthDet3"}
                                    onChange={handleChange}
                                    value={values.depthDet3}
                                    fullWidth
                                    error={Boolean(errors.depthDet3)}
                                    helperText={errors.depthDet3}
                                    label={"Depth Trigger #3"}
                                />
                                <Spacer size={5}/>
                                {errorSubmitting ? (
                                    <>
                                        <Spacer size={1}/>
                                        <Alert
                                            variant={"error"}
                                            title={"Failed to send alert"}
                                            body={
                                                "There was an issue while" +
                                                " uploading the data. Please," +
                                                " make sure you have a stable " +
                                                "internet connection"
                                            }
                                        />
                                    </>
                                ) : null}
                                {successSubmitting ? (
                                    <>
                                        <Spacer size={1}/>
                                        <Alert
                                            variant={"success"}
                                            title={"Success"}
                                            body={"Recipients should receive a notification soon."}
                                        />
                                    </>
                                ) : null}
                                <Spacer size={1}/>
                                <Button
                                    type={"submit"}
                                    variant={"contained"}
                                    size={"large"}
                                    onClick={() => {
                                        setValidateOnChange(true);
                                    }}
                                    fullWidth
                                >
                                    Trigger Alert
                                    {loadingSubmission && (
                                        <span>
                      <CircularProgress
                          style={{
                              color: theme.palette.primary[50],
                              position: "absolute",
                              top: 10,
                              left: 30,
                          }}
                      />
                    </span>
                                    )}
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
                <Spacer size={3}/>
            </InnerContainer>
        </TriggerContainer>
    );
};

export default Trigger;
