import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

interface IconBaseProps {
  iconName: string;
  fill?: number;
  wght?: number;
  grad?: number;
  opsz?: number;
  color?: string;
  size?: number;
  style?: React.CSSProperties;
}

interface IconProps {
  fill: number;
  wght: number;
  grad: number;
  opsz: number;
  color: string;
  size: number;
}

const Icon = styled.span<IconProps>`
  &.material-symbols-rounded {
    font-variation-settings: "FILL" ${({ fill }) => fill},
      "wght" ${({ wght }) => wght}, "GRAD" ${({ grad }) => grad},
      "opsz" ${({ opsz }) => opsz};
    color: ${({ color, theme }) => color || theme.palette.primary[500]};
    font-size: ${({ size }) => `${size}px`};
  }
`;

export default function IconBase({
  iconName,
  opsz = 48,
  fill = 0,
  grad = 0,
  size = 24,
  wght = 400,
  color,
  style,
}: IconBaseProps) {
  const theme = useTheme();

  if (!color) {
    color = theme.palette.primary[500];
  }

  return (
    <Icon
      className={"material-symbols-rounded"}
      opsz={opsz}
      color={color}
      wght={wght}
      size={size}
      fill={fill}
      grad={grad}
      style={style}
    >
      {iconName}
    </Icon>
  );
}
