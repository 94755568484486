import React, { useEffect, useState } from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import generateTheme from "./theme/theme";
import Routes from "./routes/Routes";
import defaultColors from "./theme/colors.json";
import "./extended-theme";

function App() {
  const [theme, setTheme] = useState(defaultColors);

  const fetchColors = async () => {
    //TODO - Fetch a color scheme json from the backend
    //TODO - If the color scheme is not available, we switch to a default

    //Something like this:
    //let data = await fetchColorScheme(cityID)
    //if(!data){
    //   use default
    // }

    let colorScheme = null;

    if (colorScheme) {
      setTheme(colorScheme);
    }
  };

  useEffect(() => {
    fetchColors();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={generateTheme(theme)}>
        <Routes />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
