import React, {
  createRef,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import {
  formatCurrency,
  formatDate,
  formatNumber,
  formatPhoneNumber,
  formatPostalCode,
} from "helpers/maskedInputFormatters";
import InputAdornment from "@mui/material/InputAdornment";
import IconBase from "components/IconBase";
import IconButton from "@mui/material/IconButton";

//Options
const CURRENCY = "CURRENCY";
const DATE = "DATE";
const PHONE = "PHONE";
const POSTAL = "POSTAL";
const NUMBER = "NUMBER";

const SearchButton = styled(IconButton)`
  && {
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.palette.primary[500]};

    &:hover {
      background-color: unset;

      .material-symbols-rounded {
        color: ${({ theme }) => theme.palette.primary[200]};
      }
    }

    .material-symbols-rounded {
      font-size: 1.2rem;
      padding-right: 4px;
      color: ${({ theme }) => theme.palette.blacks.BLACK_LOW_EMPHASIS};
    }
  }
`;

interface MaskedInputProps {
  value: string;
  initialValue: string;
  onChange: Function;
  type: string;
  handleClear?: MouseEventHandler;
  endIcon?: string;
  InputProps?: Object;
  name: string;
  fullWidth: boolean;
  error: boolean;
  helperText?: string;
  label: string;
}

/**
 * Formats a user input into various types
 * @param value
 * @param name
 * @param initialValue
 * @param onChange
 * @param type - options : phone, currency
 * @param handleClear
 * @param endIcon
 * @param InputProps
 * @param fullWidth
 * @param helperText
 * @param error
 * @param label
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function MaskedTextField({
  value,
  name,
  initialValue,
  onChange,
  type,
  handleClear,
  endIcon = "close",
  InputProps,
  fullWidth,
  helperText,
  error,
  label,
  ...props
}: MaskedInputProps) {
  const updateValue = () => {
    let formattedValue = initialValue;

    switch (type) {
      case CURRENCY:
        formattedValue = formatCurrency(formattedValue);
        break;
      case DATE:
        formattedValue = formatDate(formattedValue);
        break;
      default:
      case PHONE:
        formattedValue = formatPhoneNumber(formattedValue);
        break;
      case POSTAL:
        formattedValue = formatPostalCode(formattedValue);
        break;
      case NUMBER:
        formattedValue = formatNumber(formattedValue);
        break;
    }

    return formattedValue;
  };

  const [inputValue, setInputValue] = useState(updateValue() || "");

  const ref = createRef();

  const handleInput = (e: any) => {
    let formattedValue = e.target.value;

    switch (type) {
      case CURRENCY:
        formattedValue = formatCurrency(formattedValue);
        break;
      case DATE:
        formattedValue = formatDate(formattedValue);
        break;
      default:
      case PHONE:
        formattedValue = formatPhoneNumber(formattedValue);
        break;
      case POSTAL:
        formattedValue = formatPostalCode(formattedValue);
        break;
      case NUMBER:
        formattedValue = formatNumber(formattedValue);
        break;
    }

    if (formattedValue !== inputValue) {
      onChange(e);
    }

    setInputValue(formattedValue);
  };

  useEffect(() => {
    if (!value) {
      setInputValue("");
    }
  }, [value]);

  return (
    <TextField
      inputRef={ref}
      name={name}
      helperText={helperText}
      label={label}
      fullWidth={fullWidth}
      error={error}
      {...props}
      InputProps={{
        disableUnderline: true,
        endAdornment: inputValue && handleClear && (
          <>
            <InputAdornment position={"end"}>
              <SearchButton onClick={handleClear}>
                <IconBase iconName={endIcon} />
              </SearchButton>
            </InputAdornment>
          </>
        ),
      }}
      value={inputValue}
      onChange={(e) => {
        handleInput(e);
      }}
    />
  );
}
