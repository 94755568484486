import React, {useEffect, useState} from "react";
import {getAlert} from "../../apis/smartAlertAPI";

const Alert = () => {
    const [renderedAlert, setAlert] = useState("");
    const windowUrl = window.location.search;
    const searchParams = new URLSearchParams(windowUrl);
    const alertId = searchParams.get("id") || ""

    useEffect(() => {
        const fetch = async () => {
            const rendered = await getAlert(alertId);
            if (rendered) {
                setAlert(rendered);
            }
        };
        fetch();
    }, [alertId]);

    return (
        <div dangerouslySetInnerHTML={{__html: renderedAlert}} />
    );
};

export default Alert;
