import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getStats } from "apis/smartAlertAPI";
import IconBase from "components/IconBase";
import Spacer from "components/layout/Spacer";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const MetricsContainer = styled.div({});

const InnerContainer = styled.div({
  boxSizing: "border-box",
  maxWidth: "600px",
  padding: "0 5px",
  margin: "0 auto",
});

const Metrics = () => {
  const theme = useTheme();
  const [metricsData, setMetrics] = useState({
    valid: false,
    alerts: { counts: { total: "0" }, latest: [] },
    membership: { counts: { total: "0", phone: "0", email: "0" }, latest: [] },
  });

  useEffect(() => {
    const fetch = async () => {
      const metrics = await getStats();
      if (metrics?.valid === true) {
        setMetrics(metrics);
      }
    };
    fetch();
  }, []);

  return (
    <MetricsContainer>
      <Helmet>
        <title>MyCityAlerts - Dashboard</title>
      </Helmet>
      <InnerContainer>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Stack mt={5} mb={1} direction={"row"} alignItems={"center"}>
            <Link to={"/"}>
              <IconBase
                iconName={"account_balance"}
                grad={700}
                fill={1}
                size={22}
                style={{
                  marginRight: 5,
                }}
              />
            </Link>
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <Typography
                align={"center"}
                variant={"h4"}
                style={{
                  textTransform: "uppercase",
                  color: theme.palette.primary[500],
                  fontWeight: "700",
                  textDecoration: "none",
                }}
              >
                City<span style={{ fontWeight: "300" }}>logo</span>
              </Typography>
            </Link>
          </Stack>
        </Box>

        <Spacer size={3} />
        {metricsData?.membership && (
          <Box>
            <Typography variant={"h5"}>User count</Typography>
            <Spacer size={1} />
            <Typography variant={"body1"}>
              Total number overall: {metricsData?.membership?.counts.total}
            </Typography>
            <Typography variant={"body1"}>
              Total number receiving via email:{" "}
              {metricsData?.membership?.counts.email}
            </Typography>
            <Typography variant={"body1"}>
              Total number receiving via phone:{" "}
              {metricsData?.membership?.counts.phone}
            </Typography>
            <Spacer size={1} />
            <Typography variant={"h5"}>User list</Typography>
            <Spacer size={1} />
            <table style={{ gap: 5, width: "100%" }}>
              <thead>
                <tr style={{ display: "flex" }}>
                  <td style={{ width: "100%" }}>
                    <Typography variant={"body1"}>ID</Typography>
                  </td>
                  <td style={{ width: "100%" }}>
                    <Typography variant={"body1"}>Phone</Typography>
                  </td>
                  <td style={{ width: "100%" }}>
                    <Typography variant={"body1"}>Email</Typography>
                  </td>
                </tr>
              </thead>
              <tbody>
                {metricsData?.membership.latest.map(
                  (
                    member: { id: string; phone: string; email: string },
                    index
                  ) => {
                    if (index >= 9) {
                      return <></>;
                    }
                    return (
                      <tr
                        style={{
                          display: "flex",
                          border: `1px solid ${theme.palette.blacks.BLACK_LOW_EMPHASIS}`,
                          padding: "0.5rem",
                        }}
                      >
                        <td style={{ width: "100%", padding: "0.2rem" }}>
                          <Typography variant={"body1"}>...{member.id.slice(24)}</Typography>
                        </td>
                        <td style={{ width: "100%", padding: "0.2rem" }}>
                          <Typography variant={"body1"}>
                            {member.phone}
                          </Typography>
                        </td>
                        <td style={{ width: "100%", padding: "0.2rem" }}>
                          <Typography variant={"body1"}>
                            {member.email}
                          </Typography>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </Box>
        )}
        <Spacer size={2} />
        {metricsData?.alerts && (
          <Box>
            <Typography variant={"h5"}>Latest alerts</Typography>
            <Spacer size={1} />
            <table style={{ gap: 5, width: "100%" }}>
              <thead>
                <tr style={{ display: "flex" }}>
                  <td style={{ width: "100%" }}>
                    <Typography variant={"body1"}>Name</Typography>
                  </td>
                  <td style={{ width: "100%" }}>
                    <Typography variant={"body1"}>Channel</Typography>
                  </td>
                  <td style={{ width: "100%" }}>
                    <Typography variant={"body1"}>Depth / Severity</Typography>
                  </td>
                </tr>
              </thead>
              {metricsData.alerts.latest.map(
                (alert: {
                  name: string;
                  channel: string;
                  alias: string;
                  depth: number;
                  end: string;
                  id: string;
                  severity: string;
                  start: string;
                }) => (
                  <tr
                    style={{
                      display: "flex",
                      border: `1px solid ${theme.palette.blacks.BLACK_LOW_EMPHASIS}`,
                      padding: "0.5rem",
                    }}
                  >
                    <td style={{ width: "100%", padding: "0.2rem" }}>
                      {alert.alias}
                    </td>
                    <td style={{ width: "100%", padding: "0.2rem" }}>
                      {alert.channel}
                    </td>
                    <td style={{ width: "100%", padding: "0.2rem" }}>
                      {alert.depth} ({alert.severity})
                      <Link target="_blank" rel="noopener noreferrer" to={"/alert?id="+alert.id}>
                        <IconBase
                            iconName={"launch"}
                            grad={700}
                            fill={1}
                            size={16}
                            style={{
                              marginLeft: 5,
                              color: "green"
                            }}
                        />
                      </Link>
                    </td>
                  </tr>
                )
              )}
            </table>
          </Box>
        )}
      </InnerContainer>
    </MetricsContainer>
  );
};

export default Metrics;
