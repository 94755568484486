import { Box, Button, CircularProgress, Stack, TextField, Typography, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import { unsubscribe } from "apis/smartAlertAPI";
import IconBase from "components/IconBase";
import Spacer from "components/layout/Spacer";
import { Form, Formik } from "formik";
import Alert from "components/Alert";
import styled from "@emotion/styled";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const unsubValidations = yup.object().shape({
    email: yup
        .string()
        .email("Email address must be valid.")
        .required("Email address is required."),
});

const UnsubscribeContainer = styled.div({
});

const InnerContainer = styled.div({
    maxWidth: "345px",
    padding: "0 5px",
    margin: "0 auto"
});

const Unsubscribe = () => {
    const theme = useTheme();
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [errorSubmitting, setErrorSubmitting] = useState(false);
    const [successSubmitting, setSuccessSubmitting] = useState(false);
    const [loadingSubmission, setLoadingSubmission] = useState(false);
    const formikRef = useRef(null) as any;

    const windowUrl = window.location.search;
    const searchParams = new URLSearchParams(windowUrl);
    const tokenEmail = atob(searchParams.get("token") || "")

    const handleSubmit = async (values: {
        email: string
    }) => {
        setLoadingSubmission(true);
        setErrorSubmitting(false);
        setSuccessSubmitting(false);
        try {
            await unsubscribe(values.email);
            setSuccessSubmitting(true);
            formikRef.current?.resetForm({email: ""});
        } catch (e) {
            setErrorSubmitting(true);
        } finally {
            setLoadingSubmission(false);
        }
    };

    return (
        <UnsubscribeContainer>
            <Helmet>
                <title>MyCityAlerts - Unsubscribe</title>
            </Helmet>
            <InnerContainer>
                <Box style={{display:"flex", justifyContent:"center"}}>
                    <Stack mt={5} mb={1} direction={"row"} alignItems={"center"}>
                        <Link to={"/"}>
                            <IconBase
                                iconName={"account_balance"}
                                grad={700}
                                fill={1}
                                size={22}
                                style={{
                                    marginRight: 5,
                                }}
                            />
                        </Link>
                        <Link to={"/"} style={{textDecoration: "none"}}>
                            <Typography
                                align={"center"}
                                variant={"h4"}
                                style={{
                                    textTransform: "uppercase",
                                    color: theme.palette.primary[500],
                                    fontWeight: "700",
                                    textDecoration: "none"
                                }}
                            >
                                City<span style={{fontWeight: "300"}}>logo</span>
                            </Typography>
                        </Link>
                    </Stack>
                </Box>

                <Box mt={4}>
                    <Typography variant={"h4"}>Unsubscribe</Typography>
                    <Spacer size={1}/>
                    <Typography
                        variant={"body1"}
                        style={{color: theme.palette.blacks.BLACK_HIGH_EMPHASIS}}
                    >
                        Please enter the email address you wish to stop receiving notifications to.
                    </Typography>
                </Box>
                <Spacer size={3}/>
                <Formik
                    innerRef={formikRef}
                    validationSchema={unsubValidations}
                    initialValues={{email: tokenEmail.toString()}}
                    onSubmit={handleSubmit}
                    validateOnChange={validateOnChange}
                    enableReinitialize={true}
                >
                    {({values, handleChange, errors}) => {
                        return (
                            <Form noValidate>
                                <TextField
                                    fullWidth
                                    error={Boolean(errors.email)}
                                    helperText={errors.email}
                                    label={"Email address"}
                                    name={"email"}
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                <Spacer size={3}/>
                                {errorSubmitting ? (
                                    <>
                                        <Spacer size={1}/>
                                        <Alert
                                            variant={"error"}
                                            title={"Failed to unsubscribe"}
                                            body={
                                                "There was an issue while" +
                                                " submitting your data. Please," +
                                                " make sure you have a stable " +
                                                "internet connection"
                                            }
                                        />
                                    </>
                                ) : null}
                                {successSubmitting ? (
                                    <>
                                        <Spacer size={1}/>
                                        <Alert
                                            variant={"success"}
                                            title={"Success"}
                                            body={"Your email has been unsubscribed."}
                                        />
                                        <Spacer size={1}/>
                                        <Typography>If you did not mean to unsubscribe please click <Link to={"/"}>here</Link> to resubscribe.</Typography>
                                        <Spacer size={2}/>
                                    </>
                                ) : null}
                                <Spacer size={1}/>
                                <Button
                                    type={"submit"}
                                    variant={"contained"}
                                    size={"large"}
                                    onClick={() => {
                                        setValidateOnChange(true);
                                    }}
                                    fullWidth
                                >
                                    Unsubscribe
                                    {loadingSubmission && (
                                        <span>
                      <CircularProgress
                          style={{
                              color: theme.palette.primary[50],
                              position: "absolute",
                              top: 10,
                              left: 30,
                          }}
                      />
                    </span>
                                    )}
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            </InnerContainer>
        </UnsubscribeContainer>
    );
};

export default Unsubscribe;
