const apiVersionKey = "/v1";

const getBaseURL = (stage: string | undefined) => {
  switch (stage) {
    case "local":
    return { baseURL: "http://localhost:4001" + apiVersionKey };
    default:
      return { baseURL: "" + apiVersionKey };
  }
};

const config = getBaseURL(process.env.REACT_APP_STAGE);

export default config;
