import React from "react";
import { Box } from "@mui/material";

interface SpacerProps {
  size: number;
}

export default function Spacer({ size }: SpacerProps) {
  return <Box mt={size} />;
}
