import { createTheme, responsiveFontSizes } from "@mui/material";

//Typing for the palette
interface Color {
  primary: {
    PRIMARY_50: string;
    PRIMARY_100: string;
    PRIMARY_200: string;
    PRIMARY_300: string;
    PRIMARY_400: string;
    PRIMARY_500: string;
    PRIMARY_600: string;
    PRIMARY_700: string;
    PRIMARY_800: string;
    PRIMARY_900: string;
  };
  nonPalette: {
    WHITE: string;
    OUTLINE: string;
    RED: string;
    RED_BACKGROUND: string;
    YELLOW: string;
    YELLOW_BACKGROUND: string;
    ORANGE: string;
    ORANGE_BACKGROUND: string;
    GREEN: string;
    GREEN_BACKGROUND: string;
    BLUE: string;
    BLUE_BACKGROUND: string;
    MAROON: string;
    BLUE_GRADIENT: string;
  };
}

/**
 * Generates a theme from the provided color palette
 * @param {Color} colors
 * @returns {Theme}
 */
const generateTheme = (colors: Color) => {
  //Primary
  const PRIMARY_50 = colors.primary.PRIMARY_50;
  const PRIMARY_100 = colors.primary.PRIMARY_100;
  const PRIMARY_200 = colors.primary.PRIMARY_200;
  const PRIMARY_300 = colors.primary.PRIMARY_300;
  const PRIMARY_400 = colors.primary.PRIMARY_400;
  const PRIMARY_500 = colors.primary.PRIMARY_500;
  const PRIMARY_600 = colors.primary.PRIMARY_600;
  const PRIMARY_700 = colors.primary.PRIMARY_700;
  const PRIMARY_800 = colors.primary.PRIMARY_800;
  const PRIMARY_900 = colors.primary.PRIMARY_900;

  //Non-palette
  const WHITE = colors.nonPalette.WHITE;
  const OUTLINE = colors.nonPalette.OUTLINE;
  const RED = colors.nonPalette.RED;
  const RED_BACKGROUND = colors.nonPalette.RED_BACKGROUND;
  const YELLOW = colors.nonPalette.YELLOW;
  const YELLOW_BACKGROUND = colors.nonPalette.YELLOW_BACKGROUND;
  const ORANGE = colors.nonPalette.ORANGE;
  const ORANGE_BACKGROUND = colors.nonPalette.ORANGE_BACKGROUND;
  const GREEN = colors.nonPalette.GREEN;
  const GREEN_BACKGROUND = colors.nonPalette.GREEN_BACKGROUND;
  const BLUE = colors.nonPalette.BLUE;
  const BLUE_BACKGROUND = colors.nonPalette.BLUE_BACKGROUND;
  const MAROON = colors.nonPalette.MAROON;
  const BLUE_GRADIENT = colors.nonPalette.BLUE_GRADIENT;

  //Greys
  const GREY_LIGHT = "#FAFAFA";

  //Blacks
  const BLACK_HIGH_EMPHASIS = "#011C30DE";
  const BLACK_MEDIUM_EMPHASIS = "#011C3099";
  const BLACK_LOW_EMPHASIS = "#011C3061";

  //Whites
  const WHITE_HIGH_EMPHASIS = "#FFFFFFF2";
  const WHITE_MEDIUM_EMPHASIS = "#FFFFFFCC";
  const WHITE_LOW_EMPHASIS = "#FFFFFF66";

  const getFontWithDefault = (font = "Rubik") => {
    return `'${font}', 'Roboto', -apple-system, BlinkMacSystemFont,
'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
'Helvetica Neue', sans-serif`;
  };

  /**
   * Mui theme object
   * @type {Theme}
   */
  const theme = createTheme({
    components: {
      MuiAutocomplete: {
        defaultProps: {},
        styleOverrides: {
          root: ({ ownerState }) => {
            return {
              "& .MuiFilledInput-root": {
                backgroundColor: ownerState.value ? WHITE : GREY_LIGHT,
                border: ownerState.value
                    ? `1px solid ${OUTLINE}`
                    : `1px solid ${BLACK_MEDIUM_EMPHASIS}`,
                "&.Mui-focused": {
                  backgroundColor: WHITE,
                  borderColor: PRIMARY_200,
                  borderWidth: 2,
                  boxShadow:
                      "0 6px 10px rgba(1, 18, 32, 0.1), 0px 1px 18px rgba(1, 18, 32, 0.08), 0px 3px 5px rgba(1, 18, 32, 0.1)",

                  "& .MuiFilledInput-input": {
                    backgroundColor: WHITE,
                  },
                },
                "&.Mui-error": {
                  backgroundColor: WHITE,
                  borderColor: RED,
                },
              },
              "& .MuiFilledInput-input": {
                marginLeft: 15,
              },
              "&.Mui-focused": {
                "&& .MuiFilledInput-input": {
                  paddingLeft: 3,
                },
              },
              "& .MuiInputLabel-shrink": {
                fontWeight: 400,
                color: `${BLACK_MEDIUM_EMPHASIS} !important`,
                "&.Mui-focused": {
                  color: BLACK_MEDIUM_EMPHASIS,
                  fontWeight: 400,
                },
              },
            };
          },
          paper: {
            borderRadius: 10,
            boxShadow: `0px 16px 20px rgba(1, 48, 78, 0.1), 0px 6px 28px rgba(1, 48, 78, 0.08), 0px 10px 10px rgba(1, 48, 78, 0.1);`,
          },
          listbox: {
            "& .MuiAutocomplete-option": {
              height: 55,
              "&:hover": {
                backgroundColor: PRIMARY_50,
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          sizeLarge: {
            height: 58,
          },
          containedSizeLarge: {
            background: "linear-gradient(180deg, #1C6DD6 0%, #0C55B5 100%)",
            color: WHITE,
            boxShadow:
                "0px 6px 10px rgba(1, 18, 32, 0.1), 0px 1px 18px rgba(1, 18, 32, 0.08), 0px 3px 5px rgba(1, 18, 32, 0.1)",

            "&:hover": {
              backgroundColor: PRIMARY_200,
              boxShadow: "none",
            },
          },
          outlined: {
            border: `1px solid ${PRIMARY_500}`,
            height: 50,
            color: PRIMARY_500,

            "&:hover": {
              borderColor: PRIMARY_500,
            },
          },
          outlinedSizeLarge: {
            borderWidth: 2,
            "&:hover": {
              borderWidth: 2,
            },
          },
          contained: {
            height: 50,
          },
          root: {
            borderRadius: 10,
            height: 50,
          },
          sizeSmall: {
            textTransform: "none",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "16px",
            fontFeatureSettings: "'liga' off",
            height: 33,
            borderColor: OUTLINE,
            borderRadius: 5,
            letterSpacing: 0,
            borderWidth: 1,

            "&:hover": {
              backgroundColor: PRIMARY_50,
              borderColor: OUTLINE,
            },
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: "primary",
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            color: PRIMARY_500,
            marginLeft: 15,
            "&:hover": {
              backgroundColor: PRIMARY_50,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            "&:-webkit-autofill": {
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: 10,
              height: 39,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          inputMultiline: {
            paddingTop: 5,
            paddingLeft: "14px !important",
          },
          root: ({ ownerState }) => ({
            backgroundColor: ownerState.value ? WHITE : GREY_LIGHT,
            border: ownerState.value
                ? `1px solid ${OUTLINE}`
                : `1px solid ${BLACK_MEDIUM_EMPHASIS}`,
            borderRadius: 10,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            fontWeight: 700,
            minHeight: 76,
            "&:hover": {
              backgroundColor: PRIMARY_50,
              borderColor: OUTLINE,
            },
            "&.Mui-focused": {
              backgroundColor: WHITE,
              borderColor: PRIMARY_200,
              borderWidth: 2,
              boxShadow:
                  "0 6px 10px rgba(1, 18, 32, 0.1), 0px 1px 18px rgba(1, 18, 32, 0.08), 0px 3px 5px rgba(1, 18, 32, 0.1)",

              "& .MuiFilledInput-input": {
                paddingLeft: 26,
                backgroundColor: WHITE,
              },
            },

            "&.Mui-focused .MuiSelect-root": {
              backgroundColor: WHITE,
              borderColor: PRIMARY_200,
            },
            "&.Mui-error": {
              backgroundColor: WHITE,
              borderColor: RED,
              borderWidth: 2,
            },
            "& .MuiFilledInput-input": {
              paddingLeft: 27,
            },
          }),
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: ({ ownerState, error }) => {
            if (error === "true") {
              return {
                backgroundColor: WHITE,
                border: `2px solid ${RED}`,
                borderRadius: 10,
                marginLeft: 0,
                marginRight: 0,
                height: 76,

                "&:focus-visible": {
                  outline: "none",
                  backgroundColor: WHITE,
                  boxShadow:
                      "0px 6px 10px rgba(1, 18, 32, 0.1), 0px 1px 18px rgba(1, 18, 32, 0.08), 0px 3px 5px rgba(1, 18, 32, 0.1)",
                  borderWidth: 2,
                  borderColor: RED,
                },

                "&:hover": {
                  backgroundColor: RED_BACKGROUND,
                },
              };
            }

            return {
              backgroundColor: ownerState.control.props.checked
                  ? PRIMARY_50
                  : WHITE,
              border: ownerState.control.props.checked
                  ? `1px solid ${PRIMARY_200}`
                  : `1px solid ${OUTLINE}`,
              borderRadius: 10,
              marginLeft: 0,
              marginRight: 0,
              height: 76,

              "&:focus-visible": {
                outline: "none",
                backgroundColor: WHITE,
                boxShadow:
                    "0px 6px 10px rgba(1, 18, 32, 0.1), 0px 1px 18px rgba(1, 18, 32, 0.08), 0px 3px 5px rgba(1, 18, 32, 0.1)",
                borderWidth: 2,
                borderColor: PRIMARY_200,
              },

              "&:hover": {
                backgroundColor: PRIMARY_50,
                border: ownerState.control.props.checked
                    ? `1px solid ${PRIMARY_200}`
                    : `1px solid ${WHITE}`,
              },
            };
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {},
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          filled: ({ ownerState }) => ({
            top: 11,
            fontWeight: ownerState.shrink ? "400 !important" : 200,
            "&.Mui-focused": {
              color: BLACK_MEDIUM_EMPHASIS,
              fontWeight: 400,
            },
            "&.Mui-error": {},
          }),
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: "none",
            fontWeight: 400,
            color: BLACK_HIGH_EMPHASIS,
            cursor: "pointer",

            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: PRIMARY_700,
            width: 200,
          },
          list: {
            backgroundColor: PRIMARY_700,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: WHITE_HIGH_EMPHASIS,
            "&.Mui-focusVisible": {
              backgroundColor: PRIMARY_500,
              color: WHITE,
            },
            "&:hover": {
              backgroundColor: PRIMARY_500,
              color: WHITE,
            },
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root": {
              boxSizing: "border-box",
              borderRadius: "20px",
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            margin: "8px",
            padding: "16px",
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "filled",
          InputProps: { disableUnderline: true },
        },
        styleOverrides: {
          root: ({ ownerState }) => {
            const getColor = () => {
              if (ownerState.optional && !ownerState.value)
                return BLACK_LOW_EMPHASIS;
              return ownerState.value ? BLACK_MEDIUM_EMPHASIS : PRIMARY_700;
            };

            const tColor = getColor();

            return {
              "& .MuiInputLabel-filled": {
                color: tColor,
                fontWeight: ownerState.value ? 400 : 700,
                paddingLeft: 20,
              },
              "& .MuiInputLabel-filled.Mui-focused": {
                fontWeight: 400,
                color: BLACK_MEDIUM_EMPHASIS,
              },
            };
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          color: PRIMARY_700,
        },
      },
    },
    palette: {
      primary: {
        main: PRIMARY_200,
        50: PRIMARY_50,
        100: PRIMARY_100,
        200: PRIMARY_200,
        300: PRIMARY_300,
        400: PRIMARY_400,
        500: PRIMARY_500,
        600: PRIMARY_600,
        700: PRIMARY_700,
        800: PRIMARY_800,
        900: PRIMARY_900,
      },
      nonPalette: {
        WHITE,
        OUTLINE,
        RED,
        RED_BACKGROUND,
        YELLOW,
        YELLOW_BACKGROUND,
        ORANGE,
        ORANGE_BACKGROUND,
        GREEN,
        GREEN_BACKGROUND,
        BLUE,
        BLUE_BACKGROUND,
        MAROON,
        BLUE_GRADIENT,
      },
      blacks: {
        BLACK_LOW_EMPHASIS,
        BLACK_MEDIUM_EMPHASIS,
        BLACK_HIGH_EMPHASIS,
      },
      whites:{
        WHITE_LOW_EMPHASIS,
        WHITE_MEDIUM_EMPHASIS,
        WHITE_HIGH_EMPHASIS,
        WHITE,
      }
    },
    typography: {
      fontFamily: getFontWithDefault(),
      button: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "16px",
        letterSpacing: "1.25px",
        textTransform: "uppercase",
      },
      h2: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: `300`,
        fontSize: "51px",
      },
      h1: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: `700`,
        fontSize: "120px",
      },
      h6: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
      },
      body2: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontSize: "14px",
      },
      body1: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        /* or 130%  ??*/
        fontFeatureSettings: "'liga' off",
        color: PRIMARY_700,
      },
      h3: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "39px",
        color: PRIMARY_700,
      },
      h4: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: `300`,
        fontSize: "24px",
        color: PRIMARY_700,
      },
      h5: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: `700`,
        fontSize: "20px",
        color: PRIMARY_700,
      },
      overline: {
        color: BLACK_MEDIUM_EMPHASIS,
        letterSpacing: "1px",
      },
      caption: {
        letterSpacing: "0.4px",
      },
    },
  });

  return responsiveFontSizes(theme);
};

export default generateTheme;
