import Signup from "../screens/Signup";
import Unsubscribe from "../screens/Unsubscribe";
import Metrics from "../screens/Metrics";
import Alert from "../screens/Alert";
import Trigger from "../screens/Trigger";

/**
 * Route data
 * @type {Object}
 */
const navigator = {
  SIGN_UP: {
    title: "Sign up",
    component: Signup,
    exact: true,
    path: "/"
  },
  UNSUB: {
    title: "Unsubscribe",
    component: Unsubscribe,
    exact: true,
    path: "/unsubscribe"
  },
  ALERT: {
    title: "Alert",
    component: Alert,
    exact: true,
    path: "/alert"
  },
  DASHBOARD: {
    title: "Dashboard",
    component: Metrics,
    exact: true,
    path: "/metrics"
  },
  TRIGGER: {
    title: "Demo Trigger",
    component: Trigger,
    exact: true,
    path: "/trigger"
  },
};

export default navigator