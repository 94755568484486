import { BrowserRouter, Routes as LocalRoutes, Route } from "react-router-dom";
import routes from "./navigator";
import React from "react";

/**
 * Generates and handles routes for smart alerts frontend
 * @returns {JSX.Element}
 * @constructor
 */
const Routes = () => {
  return (
    <BrowserRouter>
      <LocalRoutes>
        {Object.entries(routes).map(([key, route]) => (
          <Route
            key={key}
            path={route.path}
            element={route.component()}
          />
        ))}
      </LocalRoutes>
    </BrowserRouter>
  );
};

export default Routes;