import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Alert from "components/Alert";
import IconBase from "components/IconBase";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import CheckboxCard from "components/ui-kit/inputs/CheckBoxCard";
import LinkButton from "components/ui-kit/inputs/buttons/LinkButton";
import Spacer from "components/layout/Spacer";
import { signupValidations } from "screens/Signup/signupValidations";
import MaskedTextField from "components/ui-kit/inputs/MaskedTextField";
import TosModal from "modals/TosModal/TosModal";
import { register } from "apis/smartAlertAPI";
import { cleanPhone } from "helpers/maskedInputCleaners";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

const SignupContainer = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const InnerContainer = styled.div({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  maxWidth: "345px",
  marginLeft: 5,
  marginRight: 5,
});

/**
 * Current smart alert entry point. The signup screen is where
 * a user can enter either a phone number or email to receive smart alerts
 * @returns {JSX.Element}
 * @constructor
 */
const Signup = () => {
  const theme = useTheme();
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [errorSubmitting, setErrorSubmitting] = useState(false);
  const [successSubmitting, setSuccessSubmitting] = useState(false);
  const [loadingSubmission, setLoadingSubmission] = useState(false);
  const formikRef = useRef(null) as any;

  const handleSubmit = async (values: {
    email: string;
    phone: string;
    tos: boolean;
  }) => {
    setLoadingSubmission(true);
    setErrorSubmitting(false);
    setSuccessSubmitting(false);
    try {
      values.phone = cleanPhone(values.phone);

      await register(values);
      setSuccessSubmitting(true);
      formikRef.current?.resetForm({ email: "", phone: "", tos: false });
    } catch (e) {
      setErrorSubmitting(true);
    } finally {
      setLoadingSubmission(false);
    }
  };

  const openTosModal = () => {
    setTosModalOpen(true);
  };
  const handleCloseTosModal = () => {
    setTosModalOpen(false);
  };
  return (

    <SignupContainer>
      <Helmet>
        <title>MyCityAlerts - Signup</title>
      </Helmet>
      <InnerContainer>
        <Stack mt={5} mb={1} direction={"row"} alignItems={"center"}>
          <Link to={"/"}>
            <IconBase
                iconName={"account_balance"}
                grad={700}
                fill={1}
                size={22}
                style={{
                  marginRight: 5,
                }}
            />
          </Link>
          <Link to={"/"} style={{textDecoration: "none"}}>
            <Typography
                align={"center"}
                variant={"h4"}
                style={{
                  textTransform: "uppercase",
                  color: theme.palette.primary[500],
                  fontWeight: "700",
                  textDecoration: "none"
                }}
            >
              City<span style={{fontWeight: "300"}}>logo</span>
            </Typography>
          </Link>
        </Stack>

        <Box mt={4}>
          <Typography variant={"h4"}>Register for MyCityAlerts</Typography>
          <Spacer size={1} />
          <Typography
            variant={"body1"}
            style={{ color: theme.palette.blacks.BLACK_HIGH_EMPHASIS }}
          >
            Please enter the email address and/or phone number where you’d want
            to receive the emergency alerts (i.e. floods and fires).
          </Typography>
        </Box>
        <Spacer size={3} />
        <Formik
          innerRef={formikRef}
          validationSchema={signupValidations}
          initialValues={{ email: "", phone: "", tos: false }}
          onSubmit={handleSubmit}
          validateOnChange={validateOnChange}
        >
          {({ values, handleChange, errors, setFieldValue }) => {
            return (
              <Form noValidate>
                <TextField
                  fullWidth
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  label={"Email address"}
                  name={"email"}
                  onChange={handleChange}
                  value={values.email}
                />
                <Spacer size={3} />
                <Typography variant={"body1"} fontWeight={"700"}>
                  SMS is safer
                </Typography>
                <Typography variant={"body2"}>
                  We recommend you register your phone number. In case the
                  Internet network is interrupted.
                </Typography>
                <Spacer size={1} />
                <MaskedTextField
                  initialValue={values.phone}
                  type={"PHONE"}
                  name={"phone"}
                  onChange={handleChange}
                  value={values.phone}
                  fullWidth
                  error={Boolean(errors.phone)}
                  helperText={errors.phone}
                  label={"Phone number"}
                />
                <Spacer size={5} />
                <CheckboxCard
                  helperText={errors.tos}
                  value={values.tos}
                  name={"tos"}
                  onChange={handleChange}
                  error={Boolean(errors.tos)}
                  label={
                    <div style={{ display: "flex" }}>
                      <p>I agree to the </p>
                      <LinkButton onClick={openTosModal}>
                        Terms of Service
                      </LinkButton>
                    </div>
                  }
                />
                {errorSubmitting ? (
                  <>
                    <Spacer size={1} />
                    <Alert
                      variant={"error"}
                      title={"Failed to submit data"}
                      body={
                        "There was an issue while" +
                        " uploading your data. Please," +
                        " make sure you have a stable " +
                        "internet connection"
                      }
                    />
                  </>
                ) : null}
                {successSubmitting ? (
                  <>
                    <Spacer size={1} />
                    <Alert
                      variant={"success"}
                      title={"Success"}
                      body={"Your account has been created."}
                    />
                  </>
                ) : null}
                <Spacer size={1} />
                <Button
                  type={"submit"}
                  variant={"contained"}
                  size={"large"}
                  onClick={() => {
                    setValidateOnChange(true);
                  }}
                  fullWidth
                >
                  Send me alerts
                  {loadingSubmission && (
                    <span>
                      <CircularProgress
                        style={{
                          color: theme.palette.primary[50],
                          position: "absolute",
                          top: 10,
                          left: 30,
                        }}
                      />
                    </span>
                  )}
                </Button>
                <TosModal
                  onAcknowledge={() => {
                    setFieldValue(`tos`, true);
                    handleCloseTosModal();
                  }}
                  open={tosModalOpen}
                  handleClose={handleCloseTosModal}
                />
              </Form>
            );
          }}
        </Formik>
      </InnerContainer>
    </SignupContainer>
  );
};

export default Signup;
