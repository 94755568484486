import React, { createRef, ReactNode, useCallback } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";

interface FormControlProps {
  error: string;
}

const StyledFormControlLabel = styled(FormControlLabel)<FormControlProps>`
  && {
    &:focus {
      box-shadow: 0 6px 10px rgba(1, 18, 32, 0.1),
        0 1px 18px rgba(1, 18, 32, 0.08), 0 3px 5px rgba(1, 18, 32, 0.1);
      border-width: 2px;
      border-color: ${({ theme }) => theme.palette.primary[200]};
    }
  }
`;

interface CheckBoxProps {
  value: boolean;
  onChange: Function;
  label: string | ReactNode;
  name: string;
  error: boolean;
  helperText: string | undefined;
}

export default function CheckboxCard({
  value,
  onChange,
  label,
  name,
  error = false,
  helperText,
  ...rest
}: CheckBoxProps) {
  const checkRef = createRef() as any;
  const controlRef = createRef() as any;

  const theme = useTheme();

  const handleOnClick = useCallback(() => {
    const node = checkRef.current as any;
    const control = controlRef.current as any;
    if (node && control) {
      node.click();
      control.focus();
    }
  }, [checkRef, controlRef]);

  return (
    <FormGroup>
      <StyledFormControlLabel
        name={name}
        error={error ? "true" : "false"}
        ref={controlRef}
        onKeyDown={(e) => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            handleOnClick();
          }
        }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        tabIndex={0}
        control={
          <Checkbox
            ref={checkRef}
            tabIndex={-1}
            {...rest}
            checked={value}
            onChange={(e) => {
              onChange(e);
            }}
          />
        }
        label={label}
      />
      <Typography variant={"caption"} color={theme.palette.nonPalette.RED}>
        {helperText}
      </Typography>
    </FormGroup>
  );
}
