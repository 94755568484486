import React, { MouseEventHandler } from "react";
import styled from "@emotion/styled";
import { css } from "@mui/material";

const Button = styled.button(
  ({ theme }) => css`
    all: unset;
    color: ${theme.palette.primary[200]};
    margin-left: 4px;
    &:hover {
      text-decoration: underline;
    }
  `
);

interface LinkButtonProps {
  onClick: MouseEventHandler;
  children: any;
}

export default function LinkButton({ onClick, children }: LinkButtonProps) {
  return (
    <Button
      type={"button"}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
