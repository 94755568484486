import * as yup from "yup";
import { formattingRegex } from "../../helpers/regexes";

export const signupValidations = yup.object().shape(
  {
    email: yup
      .string()
      .nullable()
      .notRequired()
      .when("phone", {
        is: "" || undefined,
        then: yup
          .string()
          .email("Must be a valid email")
          .required("Email address and/or phone number is required."),
      }),
    phone: yup
      .string()
      .nullable()
      .matches(formattingRegex.phone, "Phone number must be 10 digits.")
      .notRequired()
      .when("email", {
        is: "" || undefined,
        then: yup
          .string()
          .required("Phone number and/or email address is required."),
      }),
    tos: yup.bool().oneOf([true], "You must agree to the terms of service."),
  },
  [["email", "phone"]]
);
